import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import moment from "moment-timezone";

import "./header.scss";

const Header = () => {
    const getCurrentDateString = () => {
        let timezone = "America/Toronto";
        let dateFormat= "MMM D YY";
        let timeFormat = "hh:mm A"
        let dateString = moment().tz(timezone).format(dateFormat);
        let timeString = moment().tz(timezone).format(timeFormat);

        return {
            date: dateString,
            time: timeString
        }
    }
    
    // time is ticking.....
    const [dateString, setDateString] = useState(getCurrentDateString());

    useEffect(() => {
        let interval = setInterval(() => setDateString(getCurrentDateString()), 1000);
        return function cleanup() {
            clearInterval(interval);
        };
    });

    return (
        <header>
            <div className="left-align">
                <Link 
                    to={"/"}
                    className="homelink"
                >
                    <h1>PK</h1>
                </Link>
                <div className="date-time-wrapper">
                    <div className="timezone-wrapper">
                        <span className="timezone">EST</span>
                    </div>
                    <div className="date-time-column">
                        <div className="time-wrapper">
                            <span className="time">{dateString.time.split(" ")[0]}</span>
                            <span className="am-or-pm">{dateString.time.split(" ")[1]}</span>
                        </div>
                        <div className="date-wrapper"> 
                            <p className="date">{dateString.date.split(" ")[0] + " " + dateString.date.split(" ")[1]}</p>
                            <p className="date">{dateString.date.split(" ")[2]}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-align contact-link">
                <a href="mailto:pierrekochel@me.com">CONTACT</a>
            </div>
        </header>
    )
}

export default Header;